









import { defineComponent, PropType } from "@vue/composition-api";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { mapGetters } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { ICommissionMemberList, IERCProtocolsCardElement } from "../types/ERCProtocolsCardElement";
import moment from "moment";

const tableHeaders = [
  { defaultWidth: 160, value: "fullName", text: "ФИО", tooltip: "ФИО" },
  { defaultWidth: 100, value: "status", text: "Статус", tooltip: "Статус" },
  { defaultWidth: 120, value: "decision", text: "Решение", tooltip: "Решение" },
  { defaultWidth: 120, value: "date", text: "Дата согласования", tooltip: "Дата согласования" },
  { defaultWidth: 300, value: "comment", text: "Комментарий", tooltip: "Комментарий" },
  { defaultWidth: 200, value: "files", text: "Файлы", tooltip: "Файлы" },
];

export default defineComponent({
  name: "ResultBlock",
  components: {
    ApproveTable: () =>
      import(
        /* webpackChunkName: "ERCProtocolsViewApproveTable" */
        "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/components/ApproveTable.vue"
      ),
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object as PropType<Partial<IERCProtocolsCardElement>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      tableHeaders,
      cardMode: CardMode,
      section: Sections.INVENTORY_PROJECT,
    };
  },
  computed: {
    ...mapGetters("auth", ["cardModeList"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    approveList(): {
      id: number;
      fullName: string;
      status: string;
      decision: string;
      comment: string;
      files: { id: number; filename: string; uuid: string }[];
    }[] {
      return (
        this.element.expertCommissionDto?.commissionMemberList
          ?.filter((item: ICommissionMemberList) => item.memberKind?.code === "COORDINATING")
          .map((item) => {
            return {
              id: item.personId,
              fullName: item.personFullName,
              status: item.approvedState?.code || "",
              decision: item.approvedState?.title || "",
              files: item.fileList || [],
              comment: item.comment,
              date: item.date ? moment(item.date).format("YYYY-MM-DD HH:mm") : "",
            };
          }) || []
      );
    },
  },
  methods: {
    openAddApproveModal(id: string, isMainPerson?: boolean) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "AddApproveModal", {
          props: { id },
          isMainPerson,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          cbOnAddApprove: () => {},
        })
      );
    },
  },
});
